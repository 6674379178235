<template>
  <!--      Modal Actual -->
  <b-modal
    id="modal-assign-merchant"
    ref="modal"
    v-model="open"
    size="xl"
    no-close-on-backdrop
    hide-footer
    title="ASSIGN MERCHANT"
  >
    <b-card title="Rate Card - Loyal Merchants">
      <b-overlay
        blur="10px"
        opacity="0.30"
        rounded="sm"
        variant="light"
      >
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center flex-wrap">
            <b-form-group
              label="Merchants"
              class="w-75 mr-1"
            >
              <v-select
                v-model="merchant_ids"
                :options="merchants"
                multiple
                :reduce="option => option.id"
                label="name"
                deselect-from-dropdown
                @input="handleRemovedValue"
              >
                <template v-slot:option="option">
                  {{ option.name }}
                </template>
              </v-select>
            </b-form-group>
            <b-form-group
              class="mt-md-2"
            >
              <b-button
                v-if="merchant_ids.length === 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary="'Select All'"
                variant="outline-primary"
                class="mr-1"
                style="margin-top:4px;"
                @click="onClickSelectAll()"
              >
                <feather-icon icon="CheckSquareIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                class="custom-button-color"
                style="margin-top:4px;"
                :disabled="merchant_ids.length === 0"
                @click="AssignMerchants()"
              >
                Assign
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-table
            striped
            hover
            responsive
            show-empty
            :per-page="perPage"
            :items="rows"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
          >
            <template #empty>
              <TableDataFetching
                :rows="rows"
                :data-loading="dataLoading"
              />
            </template>
            <template #cell(name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <b-avatar
                    size="32"
                    :text="avatarText(data.item.name)"
                    variant="light-primary"
                  />
                </div>
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ `${data.item.name}${data.item.setting.merchant_type === 'cash' ? "&nbsp;[&nbsp;C&nbsp;]" : ''}` }}
                  </div>
                  <div class="font-weight-bold ml-1">
                    {{ data.item.ref_no }}
                  </div>
                  <div class="font-small-2 ml-1">
                    {{ timestampToDateTime(data.item.created_at) }}
                  </div>
                  <div class="ml-1">
                    <div v-if="data.item.is_active">
                      <b-badge variant="light-success">
                        Active
                      </b-badge>
                    </div>
                    <div v-if="!data.item.is_active">
                      <b-badge variant="light-danger">
                        Inactive
                      </b-badge>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(default_address)="data">
              <div v-if="data.value !== ''">
                <div
                  class="d-flex align-items-center"
                >
                  <div>
                    <div class="ml-1">
                      {{ data.value.address_line_1 }} {{ data.value.address_line_2 }}
                      {{ data.value.address_line_3 }}
                    </div>
                    <div class="font-weight-bolder ml-1">
                      {{ data.value.city.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="ml-4">
                  <span>---</span>
                </div>
              </div>
            </template>
            <template #cell(contact_no)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ data.item.default_address? data.item.default_address.phone : 'N/A' }}
                  </div>
                </div>
              </div>
            </template>
            <template #cell(admin_details)="data">
              <div class="d-flex align-items-center">
                <div>
                  <div class="font-weight-bolder ml-1">
                    {{ data.item.admin ? `${data.item.admin.first_name} ${data.item.admin.last_name}` : '' }}
                  </div>
                  <div class="ml-1">
                    {{ data.item.admin ? data.item.admin.email : '' }}
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </b-row>
      </b-overlay>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BTable,
  BCol,
  BFormGroup,
  BCard,
  // BForm,
  BBadge,
  BRow,
  BOverlay,
  BAvatar,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import { avatarText } from '@core/utils/filter'
import { timestampToDateTime } from '@core/utils/timeUtil'
import vSelect from 'vue-select'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const RateCardRepository = RepositoryFactory.get('ratecard')
const MerchantRepository = RepositoryFactory.get('merchant')

function initialState() {
  return {
    avatarText,
    timestampToDateTime,
    open: false,
    id: null,
    modalLoading: false,
    dataLoading: true,
    meta: {},
    form: {},
    merchant_ids: [],
    merchants: [],
    perPage: 20,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    page: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    fields: [
      {
        key: 'id', label: 'Ref No', sortable: true, thClass: 'd-none', tdClass: 'd-none',
      },
      {
        key: 'name', label: 'Merchant', sortable: true, thClass: 'text-left',
      },
      {
        key: 'default_address', label: 'Primary Address', sortable: false, thClass: 'text-left',
      },
      {
        key: 'contact_no', label: 'Contact Number', sortable: false, thClass: 'text-left',
      },
      {
        key: 'admin_details', label: 'Admin Details', sortable: false, thClass: 'text-left',
      },
    ],
    rows: [],
    merchantValue: [],
    merchantCount: 0,
    isRemoveConfirmed: null,
  }
}

export default {
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BButton,
    BModal,
    BAvatar,
    BCol,
    BFormGroup,
    BCard,
    // BForm,
    BBadge,
    BRow,
    BTable,
    BOverlay,
    'v-select': vSelect,
  },
  mixins: [SuccessMessage, ErrorMessage],
  data() {
    return initialState()
  },
  methods: {
    async openModal(id) {
      await Object.assign(this.$data, initialState())
      this.open = true
      this.id = id
      await this.fetchAssignedMerchantList()
      await this.fetchMerchants()
    },
    async fetchMerchants() {
      this.modalLoading = true
      try {
        this.merchants = localStorage.getItem('merchant_list') ? JSON.parse(localStorage.getItem('merchant_list')) : (await MerchantRepository.getMerchantListForDropdown()).data.data
        if (!localStorage.getItem('merchant_list')) localStorage.setItem('merchant_list', JSON.stringify(this.merchants))
      } catch (e) {
        this.showErrorMessage('Sorry for the inconvece! Please Try Again!!')
      }
      this.modalLoading = false
    },
    async fetchAssignedMerchantList() {
      this.modalLoading = true
      try {
        const { data } = (await RateCardRepository.getRateCardMerchantList(this.id))
        this.rows = data.data
        this.merchant_ids = data.data.map(item => item.id)
        this.merchantValue = data.data.map(item => item.id)
        this.merchantCount = this.merchant_ids.length
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
      this.dataLoading = false
    },
    async AssignMerchants() {
      this.modalLoading = true
      try {
        const payload = {
          merchant_ids: this.merchant_ids,
        }
        await RateCardRepository.assignMerchantList(this.id, payload)
        this.showSuccessMessage('Merchants updated successfully')
        if (!this.isRemoveConfirmed) {
          this.open = false
          this.merchants = []
        }
        this.isRemoveConfirmed = null
        this.$parent.onClickRefresh()
        localStorage.removeItem('merchant_list')
        this.fetchAssignedMerchantList()
      } catch (e) {
        this.convertAndNotifyError(e)
      }
      this.modalLoading = false
    },
    onClickSelectAll() {
      this.merchant_ids = this.merchants.map(m => m.id)
    },
    onClickDeselectAll() {
      this.merchant_ids = []
    },
    async handleRemovedValue(merchantSet) {
      if (merchantSet.length > this.merchantValue.length) {
        this.merchantValue = merchantSet.map(item => item)
      } else {
        this.modalLoading = true
        if (this.merchantCount !== 0) {
          await this.showMsgBox()
        }
        if (this.isRemoveConfirmed === false) {
          this.merchant_ids = this.merchantValue.map(item => item)
        }
        if (this.isRemoveConfirmed === true) {
          this.merchantValue = merchantSet.map(item => item)
          this.AssignMerchants()
        }
        this.modalLoading = false
      }
    },
    async showMsgBox() {
      const dialogText = 'Are you certain about removing the merchant?'
      return this.$swal({
        title: 'Are you sure?',
        text: dialogText,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Yes, confirm!',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        backdrop: 'modal-no-backdrop',
      }).then(value => {
        this.isRemoveConfirmed = value.isConfirmed
      })
    },
  },
}
</script>
<style lang="scss">
@import '../../../@core/scss/vue/libs/vue-select.scss';
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
</style>
